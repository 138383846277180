import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import type { IDialogTimeout } from './dialog-timeout.types';
import { PlatformService } from '../../../services/platform.service';
import { BehaviorSubject } from 'rxjs';
import { ContentType } from '../../../common/types/contentful';

@Component({
  selector: 'nx-dialog-timeout',
  template: `<app-dialog
      [dialogTitle]="data.title"
      [dialogSubtitle]="data.subtitle"
      [template]="fragment"
      [showCloseIcon]="false"
      [preventClose]="true"
      *ngIf="closeBaseDialog | async"
      [closeDialogEvent]="closeBaseDialog"
    ></app-dialog>
    <ng-template #fragment>
      <div class="content">
        <picture [nxContentfulImage]="data.image">
          <source />
          <source />
          <source />
          <img loading="eager" />
        </picture>
      </div>
      <div class="buttons-container">
        <nx-component-injector
          [component]="skipContent"
          (click)="closeDialog($event)"
        ></nx-component-injector>
        <nx-component-injector
          [component]="data.cta"
          (click)="closeDialog($event)"
        ></nx-component-injector>
      </div>
    </ng-template>`,
  styleUrls: ['./dialog-timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTimeoutComponent
  implements IDialogTimeout, AfterContentInit
{
  constructor(private platform: PlatformService) {}

  @Input() data!: IDialogTimeout['data'];
  public closeBaseDialog = new BehaviorSubject<boolean>(false);

  ngAfterContentInit(): void {
    if (this.platform.isServer) return;
    const timeoutModal = sessionStorage.getItem('timeoutModal');
    if (timeoutModal) return;
    setTimeout(() => {
      this.closeBaseDialog.next(true);
    }, this.data.timeout * 1000);
  }

  closeDialog(event: Event) {
    event.preventDefault();
    this.closeBaseDialog.next(false);
    sessionStorage.setItem('timeoutModal', 'closed');
  }

  get skipContent() {
    return {
      sys: {
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'nx23Link',
          },
        },
      },
      fields: {
        label: 'No me interesa',
        style: 'Transparente',
        opensNewTab: false,
        dataLayer: {
          eventName: 'ui_interaction',
          eventParams: {
            action: 'click',
            element: 'no me interesa',
            section: 'modal',
            component: this.data.title,
          },
        },
      },
    } as unknown as ContentType;
  }
}
