<ul
  class="tab-content"
  [ngClass]="{ 'mobile-none': platform.isServer }"
  [ngClass]="{ 'tab-content--small': tabContent.length > 6 }"
>
  <a
    class="mobile-content no-dropdown-button equis-body-1-medium"
    [nxAbsoluteAnchor]="homeHref"
    (click)="pushMobileHome()"
    routerLink="/"
  >
    Inicio
  </a>
  <li *ngFor="let content of tabContent">
    <ng-container *ngIf="isLink(content); else dropdown">
      <nx-navbar-tab-content-item
        class="desktop-content"
        [routerLink]="platform.getRelativeUrl(content.fields.url)"
        [contentHref]="content.fields.url"
        [itemsLength]="tabContent.length"
        [link]="content"
        >{{ content.fields.label }}
      </nx-navbar-tab-content-item>
    </ng-container>
    <ng-template #dropdown>
      <nx-navbar-tab-content-item
        *ngIf="isDropdown(content)"
        class="desktop-content"
        [link]="content"
        [hasDropdown]="isDropdown(content)"
        [itemsLength]="tabContent.length"
        [dropdown]="content.fields.dropdownContent"
        [(isDropdownActive)]="isDropdownActive"
        (isDropdownActiveChange)="passDropdownActiveValue($event)"
      >
        {{ content.fields.label }}
      </nx-navbar-tab-content-item>
    </ng-template>
    <equis-accordion
      *ngIf="isDropdown(content)"
      [isOpen]="false"
      [isNotLine]="true"
      class="mobile-content"
      (accordionEvents)="pushExpand(content)"
    >
      <div headerTitle>
        <p class="accordion-label equis-body-1-medium">
          {{ content.fields.label }}
        </p>
      </div>
      <ul>
        <li
          *ngFor="let dropdown of content.fields.dropdownContent"
          class="accordion-list"
          (click)="pushDropdownLink(dropdown)"
        >
          <a
            class="accordion-dropdown equis-body-1"
            [routerLink]="platform.getRelativeUrl(dropdown.fields.url)"
            [nxAbsoluteAnchor]="dropdown.fields.url"
            [target]="dropdown.fields.opensNewTab ? '_blank' : '_self'"
            >{{ dropdown.fields.label }}</a
          >
        </li>
      </ul>
    </equis-accordion>
    <ng-container *ngIf="isLink(content)">
      <a
        class="mobile-content no-dropdown-button equis-body-1-medium"
        [nxAbsoluteAnchor]="content.fields.url"
        [routerLink]="platform.getRelativeUrl(content.fields.url)"
        [target]="content.fields.opensNewTab ? '_blank' : '_self'"
      >
        {{ content.fields.label }}
      </a>
    </ng-container>
  </li>
  <equis-accordion
    [isOpen]="false"
    [isNotLine]="true"
    class="mobile-content"
    (accordionEvents)="pushHelpExpand()"
  >
    <div headerTitle>
      <p class="accordion-label equis-body-1-medium">Ayuda</p>
    </div>
    <ul>
      <li class="accordion-list">
        <button
          class="chatbot-button accordion-dropdown equis-body-1"
          (click)="showChatbot()"
        >
          Chat
        </button>
      </li>
      <li
        *ngFor="let item of data.helpDropdown"
        class="accordion-list"
        (click)="pushDropdownLink(item)"
      >
        <a
          class="accordion-dropdown equis-body-1"
          [routerLink]="platform.getRelativeUrl(item.fields.url)"
          [nxAbsoluteAnchor]="item.fields.url"
          [target]="item.fields.opensNewTab ? '_blank' : '_self'"
          >{{ item.fields.label }}</a
        >
      </li>
    </ul>
  </equis-accordion>
</ul>
