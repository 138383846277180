import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import type { IBlogArticles } from './blog-related-articles.types';
import { finalize, map, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContentType } from '../../../common/types/contentful';
import { PlatformService } from '../../../services/platform.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';

const blogRelatedArticlesState = makeStateKey<BlogArticles['items']>(
  'blog_related_articles_state'
);
const STATE_KEY_PAGE = makeStateKey<{
  fields: {
    category: string;
  };
}>('page');

type BlogArticles = {
  total: number;
  skip: number;
  limit: number;
  items: ContentType<any>[];
};

@Component({
  selector: 'nx-blog-related-articles',
  templateUrl: './blog-related-articles.component.html',
  styleUrls: ['./blog-related-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogRelatedArticlesComponent implements IBlogArticles, OnInit {
  constructor(
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private transferState: TransferState,
    public platform: PlatformService
  ) {}
  @Input() data!: IBlogArticles['data'];
  @ViewChild('section') public section!: ElementRef;
  public relatedArticles: BlogArticles['items'] = [];
  public currentPage = 1;

  ngOnInit(): void {
    if (!this.platform.isServer)
      this.relatedArticles = this.transferState.get(
        blogRelatedArticlesState,
        []
      )!;
    if (this.relatedArticles.length === 0)
      this.getRelatedArticles().subscribe();
  }

  private getRelatedArticles = () => {
    if (this.relatedArticles.length > 0) return of();

    const page = this.transferState.get(STATE_KEY_PAGE, null);

    const slugWithoutBlog = this.platform.route.split('/')[1];
    const category = page?.fields.category;

    return this.http
      .get<BlogArticles>(
        this.platform.getApiURL(`blog/related/${category}/${slugWithoutBlog}`)
      )
      .pipe(
        map((relatedArticles) => {
          relatedArticles.items = relatedArticles.items.map((article) => {
            // set default sizes
            const image =
              article.fields['previewImage'].fields.file.details.image;
            image.width = 500;
            image.height = 200;

            return {
              ...article,
              fields: {
                ...article.fields,
                heading: 'h6',
                image: article.fields['previewImage'],
                description: article.fields['pageDescription'],
                url: '/' + article.fields['slug'],
                actionsList: [this.generateClickToAction(article.fields)],
              },
            };
          });

          return relatedArticles.items;
        }),
        tap((relatedArticles) => {
          if (this.platform.isServer) {
            this.transferState.set(blogRelatedArticlesState, relatedArticles);
          }
          this.relatedArticles = relatedArticles;
        }),
        finalize(() => this.changeDetectorRef.detectChanges())
      );
  };

  private generateClickToAction = (fields: {
    slug: string;
    title: string;
  }) => ({
    sys: {
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'nx23Link',
        },
      },
    },
    fields: {
      url: `/${fields.slug}`,
      label: 'Leer más',
      style: 'Transparente con bordes',
      dataLayer: {
        eventName: 'ui_interaction',
        eventParams: {
          action: 'click',
          element: 'leer mas',
          section: 'content',
          component: fields.title,
          link_type: 'inbound link',
        },
      },
    },
  });
}
