<div
  class="component-container"
  [ngClass]="{ blogStyles: metadataService.isBlog }"
>
  <div class="container">
    <div class="row">
      <div
        class="component-wrapper"
        [ngClass]="[widthClass, borderClass, alignmentClass]"
      >
        <nx-rich-text-renderer [text]="data.text"></nx-rich-text-renderer>
      </div>
    </div>
  </div>
</div>
