<dialog #dialog>
  <div class="content">
    <header [ngClass]="{'no-margin': !showCloseIcon}">
      <p class="equis-h1-semibold">{{ dialogTitle }}</p>
      <p class="equis-body-1-medium" *ngIf="dialogSubtitle">{{ dialogSubtitle }}</p>

      <equis-icon-container
        *ngIf="showCloseIcon"
        icon="icon-cross"
        [size]="iconSize"
        containerColor="unset"
        iconColor="#50007f"
        style="position: absolute; top: 12px; right: 12px"
        (click)="closeModal()"
      >
      </equis-icon-container>
    </header>

    <ng-container *ngIf="component">
      <ng-container *ngComponentOutlet="component"></ng-container>
    </ng-container>

    <ng-container *ngIf="template">
      <ng-container [ngTemplateOutlet]="template"></ng-container>
    </ng-container>
  </div>
</dialog>
