import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquisDividerModule } from '@tn-equis/core/components/divider';
import {
  EquisIconContainerModule,
  EquisIconContainerSize,
} from '@tn-equis/core/components/icon-container';
import { BehaviorSubject } from 'rxjs';
import { SectionService } from '../../../services/section.service';
import { DataLayerService } from '../../../services/data-layer.service';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule, EquisDividerModule, EquisIconContainerModule],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit, OnDestroy {
  constructor(
    private section: SectionService,
    private dataLayer: DataLayerService
  ) {}
  @Input() dialogTitle!: string;
  @Input() dialogSubtitle?: string;
  @Input() preventClose = false;
  @Input() showCloseIcon = true;
  @Input() template?: TemplateRef<any>;
  @Input() closeDialogEvent = new BehaviorSubject<boolean>(false);

  @ViewChild('dialog', { static: true })
  dialog!: ElementRef<HTMLDialogElement>;
  iconSize = EquisIconContainerSize.LARGE;
  cdr = inject(ChangeDetectorRef);
  component?: any;

  ngOnInit(): void {
    this.dialog.nativeElement.showModal();

    if (this.preventClose) {
      this.dialog.nativeElement.addEventListener(
        'keydown',
        (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        }
      );

      this.dialog.nativeElement.addEventListener('close', () => {
        if (this.dialog.nativeElement.isConnected) history.back();
      });
    }
  }

  public ngOnDestroy(): void {
    this.dialog.nativeElement.close();
    this.dialog.nativeElement.remove();
  }

  public closeModal = () => {
    this.closeDialogEvent.next(false);

    this.dataLayer.push(
      {
        eventName: 'modal_app',
        eventParams: {
          action: 'click',
          element: 'cerrar',
        },
      },
      this.section.sectionName,
      undefined
    );
  };
}
