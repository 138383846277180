<div class="container">
  <app-button
    *ngIf="!platform.isServer && !show"
    [text]="
      data.disabled ? 'Comentarios deshabilitados' : 'Mostrar comentarios'
    "
    (click)="showComments()"
    [disabled]="data.disabled"
  ></app-button>
  <div id="disqus_thread" *ngIf="show"></div>
  <noscript
    >Por favor, habilita <strong>JavaScript</strong> para ver los
    comentarios.</noscript
  >
</div>
