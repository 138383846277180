import { Component, Input } from '@angular/core';

import type { ITextImage } from './text-image.types';
import { isValidBgColor } from '@/utils/color';
import { MetadataService } from '../../../services/metadata.service';

@Component({
  selector: 'nx-text-image',
  templateUrl: './text-image.component.html',
  styleUrls: ['./text-image.component.scss'],
})
export class TextImageComponent implements ITextImage {
  constructor(public metadataService: MetadataService) {}
  // REQUIRED INPUTS
  @Input() data!: ITextImage['data'];

  // PROPERTIES
  /**
   * Checks if the component has a `link`.
   */
  get hasLink() {
    return !!this.data.cta;
  }

  /**
   * Checks if the component has a full image.
   */
  get hasFullImage() {
    if (this.type === 'Hero' || this.type === 'Imagen al corte') return true;
    else return false;
  }

  get type() {
    return this.data.type ?? 'Imagen pequeña';
  }

  get backgroundColor() {
    const color = isValidBgColor(this.data.backgroundColor);
    return color.value;
  }

  // CLASSES
  /**
   * 'Hero', 'Hero secundario', 'Imagen pequeña', 'Imagen al corte' or default?
   */
  get typeClass() {
    const typeClassMap = {
      Hero: 'type__hero',
      'Hero secundario': 'type__secondary-hero',
      'Imagen pequeña': 'type__default',
      'Imagen al corte': 'type__default',
    };

    return (
      (typeClassMap as Record<string, string>)[this.type] || 'type__default'
    );
  }

  /**
   * Type of image. With padding or not?
   */
  get imageSizeClass() {
    return this.hasFullImage ? 'image__full' : 'image__padded';
  }

  get paddingClass() {
    const className = {
      'Reducir espaciado superior': 'padding_top',
      'Reducir espaciado inferior': 'padding_button',
      'Reducir espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }
}
