<section [style.backgroundColor]="backgroundColor || '#FAFAFA'">
  <div [class]="['container', 'section-wrapper', paddingClass]">
    <nx-heading-renderer
      *ngIf="data.title && data.titleHierarchy"
      class="equis-h1-semibold"
      [data]="data.titleHierarchy"
      >{{ data.title }}</nx-heading-renderer
    >
    <ul
      class="information-list"
      [ngClass]="{
        'one-column': columns === 1,
        'two-columns': columns === 2 || columns === 4,
        'three-columns': columns === 3 || columns >= 5
      }"
    >
      <li
        class="list-item"
        *ngFor="let information of data.informationList; let i = index"
      >
        <div
          *ngIf="information.fields.title || information.fields.icon"
          class="icon-and-title"
        >
          <picture
            *ngIf="information.fields.icon"
            [nxContentfulImage]="information.fields.icon"
          >
            <source />
            <source />
            <source />
            <img class="icon" loading="lazy" />
          </picture>

          <ng-container *ngIf="information.fields.title">
            <ng-container [ngSwitch]="information.fields.heading">
              <ng-container *ngSwitchCase="'h1'"
                ><h1 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h1></ng-container
              >
              <ng-container *ngSwitchCase="'h2'"
                ><h2 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h2></ng-container
              >
              <ng-container *ngSwitchCase="'h3'"
                ><h3 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h3></ng-container
              >
              <ng-container *ngSwitchCase="'h4'"
                ><h4 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h4></ng-container
              >
              <ng-container *ngSwitchCase="'h5'"
                ><h5 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h5></ng-container
              >
              <ng-container *ngSwitchCase="'h6'"
                ><h6 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h6></ng-container
              >
              <ng-container *ngSwitchDefault
                ><h2 class="title equis-h2-medium">
                  {{ information.fields.title }}
                </h2></ng-container
              >
            </ng-container>
          </ng-container>
        </div>
        <nx-rich-text-renderer
          [text]="information.fields.description"
        ></nx-rich-text-renderer>
        <nx-component-injector
          *ngIf="information.fields.cta"
          [component]="information.fields.cta"
        ></nx-component-injector>
      </li>
    </ul>
  </div>
</section>
