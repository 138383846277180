import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageService } from '@/services/page.service';
import { MetadataService } from '@/services/metadata.service';
import { filter } from 'rxjs/internal/operators/filter';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';
import { NX23Page } from './common/types/content-types';
import { distinctUntilChanged, Subject, take, takeUntil } from 'rxjs';
import { ChatbotService } from './services/chatbot.service';
import { PlatformService } from './services/platform.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: ['#deviceProperies {display: none}'],
})
export class AppComponent implements OnInit, OnDestroy {
  public experimentDeviceIdState = makeStateKey<string>('experiment_device_id');
  public experimentUserIdState = makeStateKey<string | null>(
    'experiment_user_id'
  );
  public userAgentState = makeStateKey<string>('userAgent');
  private destroyed$ = new Subject<void>();

  constructor(
    private pageService: PageService,
    private router: Router,
    private metadataService: MetadataService,
    private chatbotService: ChatbotService,
    private platformService: PlatformService,
    private activatedRoute: ActivatedRoute,
    public platform: PlatformService,
    public transferState: TransferState,
    @Optional()
    @Inject('experimentDeviceId')
    public experimentDeviceId: string,
    @Optional()
    @Inject('experimentUserId')
    public experimentUserId: string,
    @Optional()
    @Inject('headers')
    public headers: any
  ) {
    if (this.platform.isServer) {
      this.transferState.set(
        this.experimentDeviceIdState,
        this.experimentDeviceId
      );
      this.transferState.set(this.experimentUserIdState, this.experimentUserId);
      this.transferState.set(this.userAgentState, this.headers['user-agent']);
    }
  }

  public ngOnInit(): void {
    // initial load at application start and subscription to route change events
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        mergeMap(this.loadPageAndMetadata),
        tap(this.updateMetadata)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadPageAndMetadata = () =>
    this.pageService.getPage().pipe(
      takeUntil(this.destroyed$),
      tap((page) => {
        this.pageService.sections.next(page.fields.sections);
        if (!this.platformService.isServer) this.shouldOpenChatbot();
        return of(page);
      })
    );

  private updateMetadata = (page: NX23Page) => {
    this.metadataService.updateMetadata({
      title: page?.fields.title,
      image:
        'https:' +
        page?.fields?.previewImage?.fields?.file?.url +
        '?fm=jpg&q=85&w=1200',
      imageAlt: page?.fields?.previewImage?.fields?.description as string,
      description: page?.fields.pageDescription,
      keywords: page?.fields.pageKeywords,
      structuredData: page?.fields.structuredData,
      alternateHrefLangUrl: page.fields?.alternateHrefLangUrl,
      index: page?.fields.index,
    });
    this.metadataService.isBlog = !!page?.fields.category;
  };

  private shouldOpenChatbot = () => {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['openWebChat'] === 'true') {
        this.chatbotService.show();
      }
    });
  };
}
