import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

import type { IBlogComments } from './blog-comments.types';
import { PlatformService } from '../../../services/platform.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'nx-blog-comments',
  templateUrl: './blog-comments.component.html',
  styleUrls: ['./blog-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogCommentsComponent implements IBlogComments, OnDestroy, OnInit {
  constructor(
    public platform: PlatformService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  @Input() data!: IBlogComments['data'];
  private count?: HTMLScriptElement;
  private comments?: HTMLScriptElement;
  public commentsDisabled?: boolean;
  public show = false;

  ngOnInit(): void {
    if (this.platform.isServer) return;
    this.showComments();
  }

  public showComments() {
    if (this.data.disabled) return;
    this.show = true;

    // Add script count by disqus.com
    this.count = this.renderer.createElement('script') as HTMLScriptElement;
    this.count.setAttribute('id', 'dsq-count-scr');
    this.count.setAttribute('src', '//naranjax.disqus.com/count.js');
    this.count.async = true;
    this.renderer.appendChild(this._document.body, this.count);

    // Add script comments by disqus.com
    this.comments = this.renderer.createElement('script') as HTMLScriptElement;
    this.comments.setAttribute('src', 'https://naranjax.disqus.com/embed.js');
    this.comments.setAttribute('data-timestamp', new Date().toString());
    this.renderer.appendChild(this._document.body, this.comments);
  }

  ngOnDestroy(): void {
    if (this.count) this.renderer.removeChild(this._document.body, this.count);
    if (this.comments)
      this.renderer.removeChild(this._document.body, this.comments);
  }
}
