import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * Service for exposing platform-specific functionality and data
 */
export class PlatformService {
  constructor(@Inject(PLATFORM_ID) platformId: Object, private router: Router) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    // Take the port from the environment variables to ensure the PORT is the same
    // in the browser and the server
    const port = environment.PORT;
    this.url = `http://localhost:${port}`;
  }

  // PROPERTIES
  public isBrowser: boolean = false;
  public isServer: boolean = false;
  public url: string;

  /**
   * The current route, without the leading slash
   */
  public get route() {
    // Remove leading slash with `slice()`
    // /xxx -> xxx
    return this.router.url.slice(1);
  }

  /**
   * Whether the current device is a mobile device or not
   */
  public get isMobile(): boolean {
    // Usage of UserAgent as a way to differentiate devices is discouraged as stated in https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    if (this.isServer) {
      return false;
    }

    return (
      'maxTouchPoints' in navigator &&
      navigator.maxTouchPoints > 0 &&
      window.innerWidth <= 768
    );
  }

  // METHODS
  /**
   * Generates the URL for an API route
   * @param apiRoute API route to generate URL for
   * @returns A string containing the URL for the API route
   */
  public getApiURL(apiRoute: string) {
    if (this.isServer) {
      return `${this.url}/api/${apiRoute}`;
    }

    return `/api/${apiRoute}`;
  }

  /**
   * The absolute URL for the current route, or the route if its already absolute
   * @param route Optional route to generate the absolute URL for. If not provided, the page's current route is used
   */
  public getAbsoluteURL(route?: string): string {
    const isRelative =
      !!route?.startsWith?.('/') || !!route?.startsWith?.('./');

    if (isRelative || !route) {
      return `${environment.HOST_URL}${route ?? this.router.url}`;
    } else return route;
  }

  public getRelativeUrl(url: string) {
    if (url?.startsWith('/')) return url;
    return null;
  }
}
