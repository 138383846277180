import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { register } from 'swiper/element';
import {
  Navigation,
  Pagination,
  Autoplay,
  type Swiper,
  type SwiperOptions,
} from 'swiper';

register();

import {
  NX23QuicklinkFields,
  NX23TextImageFields,
} from '@/types/content-types';
import { SectionService } from '@/services/section.service';
import { DataLayerService } from '@/services/data-layer.service';

import type { ICarousel } from './carousel.types';
import { PlatformService } from '../../../services/platform.service';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../variant-container/variant-container.component';

@Component({
  selector: 'nx-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [SectionService],
})
export class CarouselComponent implements ICarousel, AfterViewInit {
  constructor(
    public platform: PlatformService,
    private dataLayer: DataLayerService,
    @Self() private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {
    this.section.sectionName = 'carousel';
  }
  // REQUIRED INPUTS
  @Input() data!: ICarousel['data'] | any;

  // SWIPER ELEMENTS
  @ViewChild('swiperRef', { static: false }) swiperRef?: ElementRef;
  @ViewChild('prevRef', { static: false }) protected _prevRef?: ElementRef;
  @ViewChild('nextRef', { static: false }) protected _nextRef?: ElementRef;
  @ViewChild('paginationRef', { static: false })
  protected _paginationRef?: ElementRef<HTMLDivElement>;
  public swiper: Swiper | null = null;

  // SWIPER CONFIG
  private get breakpoints(): SwiperOptions['breakpoints'] {
    if (this.data.itemsPerSlide === 'Automatico') {
      return {
        0: { slidesPerView: 1.5 },
        330: { slidesPerView: 2.5 },
        576: { slidesPerView: 3.5 },
        768: { slidesPerView: 3.5 },
        1025: { slidesPerView: 4 },
        1200: { slidesPerView: 5 },
      };
    } else {
      return {
        0: { slidesPerView: 1 },
      };
    }
  }

  private get swiperOptions(): SwiperOptions {
    const autoplay = this.data.itemsPerSlide === 'Unico' && {
      delay: 7000,
    };

    return {
      modules: [Navigation, Pagination, Autoplay],
      breakpoints: {
        ...this.breakpoints,
      },
      speed: 500,
      autoplay,
      pagination: {
        enabled: true,
        clickable: true,
        // Bind the HTML element used for pagination
        el: this._paginationRef?.nativeElement,
        renderBullet: (_, className) => `<span class="${className}"></span>`,
      },
      navigation: {
        enabled: true,
        // Bind the HTML elements used for navigation
        prevEl: this._prevRef?.nativeElement,
        nextEl: this._nextRef?.nativeElement,
      },
      // Style the bullets
      injectStyles: [
        `
        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
          margin: 0 !important;

          background-color: white;
          opacity: 1;

          border-radius: 100%;
          border: 1px solid #50007f;
        }
        .swiper-pagination-bullet-active {
          background-color: #50007f;
        }
      `,
      ],
    };
  }

  private initSwiper() {
    // If already initialized or no 'swiperRef' found, do nothing
    if (this.swiper || !this.swiperRef) return;

    const element = this.swiperRef.nativeElement;

    if (element) {
      // Assign config
      Object.assign(element, this.swiperOptions);
      // Call inner function as explained by the documentation
      // https://swiperjs.com/element#parameters-as-props
      if (!this.platform.isServer) {
        element.initialize();
      }

      // Store Swiper instance as explained by the documentation
      // https://swiperjs.com/element#access-to-swiper-instance
      this.swiper = element.swiper;
    }
  }

  isQuicklinkFields(
    data: NX23QuicklinkFields | NX23TextImageFields
  ): data is NX23QuicklinkFields {
    return 'icon' in data;
  }

  isTextImageFields(
    data: NX23QuicklinkFields | NX23TextImageFields
  ): data is NX23TextImageFields {
    return !('icon' in data);
  }

  // DATALAYER METHODS
  addPaginationPush() {
    // Add push event to each pagination bullet
    this._paginationRef?.nativeElement.childNodes.forEach((child, i) => {
      child.addEventListener('click', () => {
        let experiment!: AmplitudeExperiment;
        if (this.variantContainerComponent) {
          const variant = this.variantContainerComponent.getVariant();
          const key = this.variantContainerComponent.data.experiment.key;
          experiment = { key, variant };
        }

        this.dataLayer.push(
          {
            eventName: 'ui_interaction',
            eventParams: {
              action: 'switch',
              element: `${i + 1}`,
              section: this.data.dataLayer.eventParams.section,
            },
          },
          this.section.sectionName,
          experiment
        );
      });
    });
  }
  pushNavigation(el: 'next' | 'prev') {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      {
        eventName: 'ui_interaction',
        eventParams: {
          action: 'switch',
          element: `slide ${el === 'next' ? 'siguiente' : 'anterior'}`,
          section: this.data.dataLayer.eventParams.section,
        },
      },
      this.section.sectionName,
      experiment
    );
  }

  // HOOKS
  ngAfterViewInit(): void {
    this.initSwiper();
    this.addPaginationPush();
  }
}
