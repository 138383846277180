import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogTimeoutComponent } from './dialog-timeout.component';
import { DialogComponent } from '../../tooling/dialog/dialog.component';
import { DirectivesModule } from '@/directives/directives.module';
import { ComponentInjectorModule } from '@/tooling/component-injector/component-injector.module';
import { SectionService } from '../../../services/section.service';

@NgModule({
  declarations: [DialogTimeoutComponent],
  imports: [
    CommonModule,
    DialogComponent,
    ComponentInjectorModule,
    DirectivesModule,
  ],
  providers: [SectionService],
  exports: [DialogTimeoutComponent],
})
export default class DialogTimeoutModule {
  public static component = DialogTimeoutComponent;
}
